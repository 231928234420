import styled from 'styled-components';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const UseCaseStyles = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 96px;
  position: relative;
  min-height: 890px;
  @media (max-width: 768px) {
    padding: 64px 35px;
  }
  @media (max-width: 991px) {
    padding: 64px 0;
    .ref-col {
      order: 12;
    }
  }
  .ref-col {
    display: flex;
    align-items: center;
    position: relative;
  }
  .kicker {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${colors.base.white};
    margin-bottom: 32px;
  }
  .heading {
    ${font('display', 'xl', '700')}
    color: ${colors.base.white};
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 35.16px;
    }
  }
  .subhead {
    font-style: normal;
    font-weight: normal;
    font-size: 22.5px;
    line-height: 150%;
    letter-spacing: -0.27px;
    color: #f0f1f7;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .img-wrapper {
    box-shadow: 0px 24px 48px -12px rgba(12, 24, 90, 0.25);
    border-radius: 15px;
    max-width: 570px;
    margin: 0 auto;
    max-height: 320px;
    position: relative;
    @media (max-width: 991px) {
      max-width: 100%;
      max-height: 100%;
    }
    .gatsby-image-wrapper {
      height: 320px;
      @media (max-width: 991px) {
        height: auto;
      }
    }
    img {
      height: 100%;
      box-shadow: 0px 24px 48px -12px rgba(12, 24, 90, 0.25);
      border-radius: 15px;
    }
    .watch-video-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: var(--white);
      span {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 80px;
        svg {
          color: #1c3eff;
          font-size: 24px;
          margin-left: 4px;
        }
      }
    }
  }
  .metric-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28.13px;
    line-height: 120%;
    letter-spacing: -0.3px;
    color: ${colors.base.white};
    margin: 32px 0;
  }
  .stat-number {
    font-style: normal;
    font-weight: bold;
    font-size: 22.5px;
    line-height: 100%;
    letter-spacing: -0.27px;
    color: ${colors.green[400]};
    margin-bottom: 16px;
    padding-top: 3px;
    padding-left: 16px;
    border-left: 2px solid ${colors.green[400]};
    vertical-align: middle;
    @media (max-width: 991px) {
      color: ${colors.green[400]};
    }
  }
  .stat-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.16px;
    color: #f0f1f7;
    padding-left: 16px;
    @media (max-width: 768px) {
      font-size: 14.4px;
    }
  }
`;
