import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container, Row, Col } from 'react-bootstrap';
import { renderRule, StructuredText } from 'react-datocms';
import { isParagraph } from 'datocms-structured-text-utils';
import ModalVideo from 'react-modal-video';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Modal from '../../Modal';
import OptimizedImage from '../../../utils/OptimizedImage';
import { getYoutubeId } from '../../../utils/getYoutubeId';
import { UseCaseStyles } from '../../../styles/Components/UseCase/UseCaseStyles';
import Icon from '../../../styles/atoms/icons';

const UseCase = ({ component }) => {
  const {
    backgroundImage,
    bgImage,
    heading,
    subheadingStructuredText,
    video,
    metric,
  } = component;
  const [play, setPlay] = useState(false);
  const isWithCTA = !!video?.callToAction;
  const youtubeId = getYoutubeId(video?.youtubeUrl);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 991px)');
  const bgImg = isMobile
    ? bgImage?.backgroundImage?.url || backgroundImage?.url
    : isTablet
    ? bgImage?.imageMobile?.url || backgroundImage?.url
    : backgroundImage?.url;
  return (
    <UseCaseStyles backgroundImage={bgImg}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={12}>
            <p className="kicker">Case Study</p>
            <h2 className="heading">{heading}</h2>
            <div className="subhead">
              <StructuredText
                data={subheadingStructuredText}
                customRules={[
                  renderRule(isParagraph, ({ children }) => {
                    return children[0] && <p>{children}</p>;
                  }),
                ]}
                renderInlineRecord={({ record }) => {
                  switch (record.__typename) {
                    case 'DatoCmsCallToAction':
                      return (
                        <CallToAction
                          variant="secondary"
                          value={record?.label}
                          link={record?.url}
                          key={record?.label}
                          pop={record?.popupReference}
                        />
                      );
                    default:
                      return null;
                  }
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={12} className="ref-col">
            <div className="img-wrapper">
              {video?.videoThumbnail?.image?.gatsbyImageData ? (
                <OptimizedImage
                  image={video?.videoThumbnail?.image?.gatsbyImageData}
                  alt={video?.videoThumbnail?.image?.title}
                />
              ) : (
                <OptimizedImage
                  src={video?.videoThumbnail?.image?.url}
                  alt={video?.videoThumbnail?.image?.title}
                />
              )}
              <div className="watch-video-btn">
                <span role="button" tabIndex={0} onClick={() => setPlay(true)}>
                  <Icon id="FaPlay" />
                </span>
              </div>
            </div>
          </Col>
          <Col lg={12} md={12}>
            <p className="metric-title">Key Results</p>
          </Col>
          {metric &&
            metric.map((item, idx) => (
              <Col lg={4} md={12} ket={`metric_${idx}`}>
                <p className="stat-number">{item?.statNumber}</p>
                <p className="stat-label">{item?.statLabel}</p>
              </Col>
            ))}
        </Row>
        {typeof window !== 'undefined' && (
          <ModalVideo
            channel="youtube"
            isOpen={play && !isWithCTA}
            videoId={youtubeId}
            onClose={() => setPlay(false)}
          />
        )}
        <Modal
          show={play && isWithCTA}
          reference={video}
          onClose={() => setPlay(false)}
        />
      </Container>
    </UseCaseStyles>
  );
};

export default UseCase;
